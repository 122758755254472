/* eslint no-console:0 */
/* eslint-disable no-undef */

import '@hotwired/turbo-rails'
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import IconsController from '../controllers/icons_controller'

const application = Application.start()

registerControllers(application, [IconsController])
